<template>
  <router-view></router-view>
</template>

<script setup>

import {ref} from 'vue';
import {ElMessageBox} from "element-plus";

const deferredPrompt = ref(null);

window.addEventListener("beforeinstallprompt", e => {
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt.value = e;

  ElMessageBox.confirm('Deseja instalar o aplicativo agora?',{
    confirmButtonText: 'Instalar Agora!',
    cancelButtonText: 'Não'})
      .then(() => {
        install();
      })
      .catch(() => {
        dismiss();
      })

});
window.addEventListener("appinstalled", () => {
  deferredPrompt.value = null;
});


const dismiss = async ()=>{
  this.deferredPrompt = null;
}

const install = async ()=>{
  this.deferredPrompt.prompt();
}




</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
