import { createStore } from 'vuex'


// Create a new index instance.
const store = createStore({
    state () {
        return {
            auth: false
        }
    },
    getters: {
    },
    mutations: {
        setAuth (state,auth) {
            state.auth = auth;
        }
    },
    actions: {
    },
    modules: {
    }
})

export default store;