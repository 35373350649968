
import {createRouter,createWebHashHistory} from 'vue-router';
import store from './store/index'

const routes = [
    {path: '/:pathMatch(.*)*',redirect: '/home'},
    { path: '/home', component: ()=>import('./templates/home'), meta: {closed: true}},
    {path: '/login', component: ()=>import('@/templates/login.vue'),name: 'Login', meta: {public: true}}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    if(store.state.auth || to.meta.public){
        next();
    }else{
        next('/login')
    }
})

export default router;