import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";


import store from "./store/index"
import routes from './routes.js'

import VueQrcodeReader from "vue3-qrcode-reader";


createApp(App).use(VueQrcodeReader).use(store).use(ElementPlus).use(routes).mount('#app')
